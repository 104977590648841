import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link to="/">Home</Link>    
            </li>
            
            <li><a href="#nosotros">Nosotros</a>
            </li>

            <li className="with-megamenu"><a href="#proyectos">Proyectos</a>
            </li>

            <li className="has-droupdown"><a href="#clientes">Clientes</a>
            </li>

        </ul>
    )
}
export default Nav;
