import React from 'react';
import VideoTwo from '../video/VideoTwo';
import { FiCheck } from "react-icons/fi";


const PopupData = [
    {
        id: "01",
        image: "./images/bg/bg-image-4.jpg",
        popupLink: [
            'https://www.youtube.com/watch?v=jUk1eU_N7lg',
        ],
    }
]

const AboutFour = ({image}) => {
    return (
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6">
                        {PopupData.map((item) => (
                            <div className="video-btn" key={item.id}>
                                <VideoTwo imagename={`${image}`} galleryItem={item} />
                            </div>
                        ))}
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title">Somos un equipo comprometido <br />con la <strong>satisfacción del cliente.</strong></h3>
                                {/* <ul className="feature-list">
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Timbrado de CFDI</h4>
                                            <p className="text">Contamos con más de 7 años de experiencia, como integrador de emisión y timbrado masivo de CFDI.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Desarrollo a la medida</h4>
                                            <p className="text">Ofrecemos soluciones personalizadas para cada uno de nuestros clientes. </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Soporte remoto y en sitio</h4>
                                            <p className="text">Privilegiamos los </p>
                                        </div>
                                    </li>
                                </ul> */}
                                <p>
                                    Desde el año 2012 hemos aportado valor a nuestros clientes, a través de la eficiencia de soluciones y soporte oportuno. En Adinc creemos que los sistemas deben responder a las necesidades propias de cada organización, para lograr el cumplimiento con la autoridad y satisfacción del cliente. 
                                </p>
                                <p>
                                    Somos una empresa mexicana dedicada al desarrollo de software, aplicaciones web y móvil, redes y soporte técnico en sitio y remoto, implantamos soluciones que cumplen con los estándares más altos de calidad, ofrecemos soluciones de comunicación para grupos de trabajo.  
                                </p>

                                {/* <div className="about-btn mt--30">
                                    <a className="btn-default" href="#">Contáctanos</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFour
